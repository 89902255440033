<template>
  <Toast />

  <div class="card">
    <DataTable
      ref="dt"
      :value="records"
      dataKey="id"
      :paginator="true"
      :rows="10"
      class="dt"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
      v-model:filters="filters"
      :globalFilterFields="['title','subtitle','keywords','author']"
      stateStorage="session" stateKey="aagespoeld"
    >
      <template #header>
        <div class="table-header pop20">
          <div class="p-d-flex p-jc-between">
            <div>Manage aangespoeld</div>
            <div>
              <Button
                label="New item"
                icon="pi pi-plus"
                class="p-button-success p-mr-2"
                @click="openNew"
              />
            </div>
            <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
          </div>
        </div>
      </template>

      <Column field="id" header="Id" :sortable="true"></Column>
      <Column header="Image">
        <template #body="slotProps">
          <img
            :src="
              `${$config.images.server}/images/${slotProps.data.media}?width=80&height=80&fit=contain&background=ffffff`
            "
            :alt="slotProps.data.title"
            class="small_record_image"
            v-if="slotProps.data.media"
          />
          <img
            src="@/assets/images/_noimage.png"
            class="record_image"
            v-else
          />
        </template>
      </Column>
      <Column field="title" header="Title" :sortable="true"></Column>
      <Column field="subtitle" header="Subtitle" :sortable="true"></Column>
      <Column field="keywords" header="Keywords" :sortable="true"></Column>
      <Column field="author" header="Author" :sortable="true"></Column>
      <Column field="updatedAt" header="Modified" :sortable="true"></Column>
      <Column>
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editrecord(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDeleterecord(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    header="Header"
    v-model:visible="displayUpload"
    :style="{ width: '50vw' }"
  >
    <FileUpload
      ref="up"
      name="mediaFile"
      :multiple="false"
      accept="image/*"
      :fileLimit="1"
      :maxFileSize="1000000"
      :url="`${$config.iwib.server}/records/upload`"
      @before-send="prepareUpload"
      @upload="onUpload"
    >
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </Dialog>

  <Dialog
    v-model:visible="recordDialog"
    :style="{ width: '450px' }"
    header="Aangespoeld record details"
    :maximizable="true"
    :modal="true"
    class="p-fluid"
  >
    <center>
      <img
        ref="currentImage"
        :src="
          `${$config.images.server}/images/${record.media}?width=150&height=150&fit=contain&background=ffffff`
        "
        :alt="record.title"
        class="big_record_image"
        v-if="record.media"
      />
      <img src="@/assets/images/_noimage.png" class="record_image" v-else />
    </center>
    <div class="upload">
      <Button
        label="upload new image"
        icon="pi pi-external-link"
        @click="openUpload"
      />
    </div>
    <div class="p-field"> 
      <label for="title">Title</label>
      <InputText
        id="title"
        v-model.trim="record.title"
        required="true"
        autofocus
        :class="{ 'p-invalid': submitted && !record.title }"
      />
      <small class="p-error" v-if="submitted && !record.title"
        >Title is required.</small>
    </div>
    <div class="p-field">
      <label for="subtitle">Subtitle</label>
      <InputText id="subtitle" v-model.trim="record.subtitle" required="true"         :class="{ 'p-invalid': submitted && !record.subtitle }"
      />
      <small class="p-error" v-if="submitted && !record.subtitle"
        >Subtitle is required.</small>

    </div>
    <div class="p-field">
      <label for="keywords">Keywords (separate keywords with ;)</label>
      <InputText id="keywords" v-model.trim="record.keywords" required="true"         :class="{ 'p-invalid': submitted && !record.keywords }"
      />
      <small class="p-error" v-if="submitted && !record.keywords"
        >Keywords is required.</small>

    </div>
    <div class="p-field">
      <label for="shortdescription">Shortdescription</label>
      <InputText id="shortdescription" v-model.trim="record.shortdescription" required="true"         :class="{ 'p-invalid': submitted && !record.shortdescription }"
      />
      <small class="p-error" v-if="submitted && !record.shortdescription"
        >Short description is required.</small>

    </div>
    <div class="p-field">
      <label for="description">Description</label>
      <QuillEditor ref="editor" id="editor" theme="snow" toolbar="full"  :modules="modules" contentType="html" v-model:content="record.description" />

 </div>
    <template #footer>
      <Button 
        label="Cancel"
        icon="pi pi-times" 
        class="p-button-text"
        @click="hideDialog"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        @click="saverecord(false)"
      />
      <Button
        label="Save &amp; close"
        icon="pi pi-check"
        class="p-button-text"
        @click="saverecord(true)"
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="deleterecordDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="record"
        >Are you sure you want to delete the record <b>{{ record.title }}</b
        >?</span
      >
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleterecordDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleterecord"
      />
    </template>
  </Dialog>
</template>

<script>
import recordService from "../../services/record_service"
import {FilterMatchMode} from 'primevue/api';

import { QuillEditor } from '@vueup/vue-quill'
import BlotFormatter from 'quill-blot-formatter'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  components: {
    QuillEditor
  },
  setup: () => {
    const modules = {
      name: 'blotFormatter',  
      module: BlotFormatter, 
      options: {/* options */}
    }
    return { modules }
  },
  data() {
    return {
      records: null,
      recordDialog: false,
      deleterecordDialog: false,
      record: {},
      submitted: false,
      isnew: false,
      files: [],
      displayUpload: false,
      filters: null
    }
  },
  recordservice: null,
  created() {
    this.recordservice = new recordService()
    this.filters = {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}}
  },
  mounted() {
    this.recordservice.getAangespoeld().then((data) => (this.records = data))
  },
  methods: {
    openUpload() {
      this.displayUpload = true
    },
    closeUpload() {
      this.displayUpload = false
    },
    openNew() {
      this.record = {}
      this.submitted = false
      this.recordDialog = true
      this.isnew = true
    },
    hideDialog() {
      this.recordDialog = false
      this.submitted = false
    },
    saverecord(close) {
      this.submitted = true 
      //defaults for aangespoeld
      this.record.recordtype = 4
      this.record.recordcategory = 0
      this.record.connectedId = 0
      this.record.author = JSON.parse(localStorage.getItem("user")).username
      this.record.relatedmedia = "0"
      this.record.coordinates = "0"
      this.record.linkedId = 0

      if (this.record.title.trim()) {
        if (this.record.id) {
          this.recordservice
            .update(this.record)
            .then(() => {
              this.recordservice
                .getAangespoeld()
                .then((data) => (this.records = data))
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "record Updated",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        } else {
          this.recordservice
            .add(this.record)
            .then(() => {
              this.recordservice
                .getAangespoeld()
                .then((data) => (this.records = data))
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "record Created",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        }
        if (close) {
          this.recordDialog = false
          this.record = {}
        }
      }
    },
    editrecord(record) {
      this.isnew = false
      this.recordservice.getById(record.id).then((data) => (this.record = { ...data }))
      // this.record = { ...record }
      this.recordDialog = true
    },
    confirmDeleterecord(record) {
      this.record = record
      this.deleterecordDialog = true
    },
    deleterecord() {
      this.recordservice
        .delete(this.record.id)
        .then(() => {
          this.recordservice
            .getAangespoeld()
            .then((data) => (this.records = data))
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "record Created",
            life: 3000,
          })
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 3000,
          })
        })
      this.deleterecordDialog = false
      this.record = {}
    },
    onUpload(event) {
      this.record.media = event.files[0].name
      this.displayUpload = false
    },
    prepareUpload(event) {
      let user = JSON.parse(localStorage.getItem("user"))
      //event.xhr.open('POST', 'http://localhost:4000/records/upload', true);
      //event.xhr.withCredentials = true;
      event.xhr.setRequestHeader("Authorization", "Bearer " + user.token)
    },
    ed_change(v) {
      console.info(v)
    },
    fpc (cb) {
          var input = document.createElement('input'); //getElementById('my-file'); 
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.onchange = function () {
            var file = this.files[0];
            var reader = new FileReader();
            reader.onload = function () {
              var id = 'blobid' + (new Date()).getTime();
              //the es-lint comment is crucial, it disables the undef rule for this line
              var blobCache =  tinymce.activeEditor.editorUpload.blobCache;// eslint-disable-line
              var base64 = reader.result.split(',')[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
         }
  },
}
</script>

<style scoped>
.small_record_image {
  width: 100px;
}
.big_record_image {
  width: 300px;
}
</style>
